@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@font-face {
  font-family: 'FontAwesome';
  src: url('https://cdn2.hubspot.net/hubfs/2529352/fonts/fontawesome-webfont.svg') format('svg'),
    url('https://cdn2.hubspot.net/hubfs/2529352/fonts/fontawesome-webfont-1.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  max-width: 100vw;
  overflow-x: hidden;
  background: #f4f7fb;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: none;
  -webkit-appearance: none;
  box-shadow: none !important;
}

.printOnly {
  display: none;
}

a:link {
  text-decoration: none;
}

:not(:root):fullscreen::backdrop {
  position: fixed;
  inset: 0px;
  background: #ffffff;
}

:fullscreen {
  overflow-y: scroll !important;
  padding-bottom: 3rem;
}