.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-float-label > label.p-error {
  color: #e24c4c;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-inputwrapper > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  border-color: #007bff;
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #007bff;
  color: #ffffff;
  border-radius: 4px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-multiselect .p-multiselect-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-multiselect.p-invalid.p-component {
  border-color: #dc3545;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
  padding: 0.5rem 0.75rem;
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
  padding-right: 1.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #212529;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  margin: 0 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #212529;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #007bff;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #212529;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #212529;
  background: #ffffff;
  font-weight: 600;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #212529;
  background: transparent;
}

.p-input-filled .p-multiselect {
  background: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

.p-button {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

.p-button-outlined {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  background: #ffffff;
  border: 1px solid #f4f7fb;
  box-sizing: border-box;
  border-radius: 4px;
}

.p-button.p-button-danger {
  background-color: transparent;
  border-color: transparent;
  color: transparent;
  border-radius: 50%;
  height: 2.357rem;
}

.p-button.p-button-danger.p-button-text:hover {
  transition: color 0.5s;
  color: black;
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
}

tr:hover.p-button {
  color: #16709e;
  transition: color 0.5s;
}

.p-dropdown {
  background: #ffffff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  width: 100%;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #6c757d;
  right: 2.357rem;
}

.p-dropdown.p-invalid.p-component {
  border-color: #f44336;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #495057;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.5rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.5rem;
  color: #6c757d;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
  padding-right: 3rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
  right: 2rem;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: white;
  background: #44b1e7;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: white;
  background: #44b1e7;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: white;
  background: #16709e;
  font-weight: 600;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: white;
  background: #16709e;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 3rem;
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
  border-color: #e24c4c;
}

.p-inputtext {
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}

.p-inputtext:enabled:hover {
  border-color: #3b82f6;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
  border-color: #3b82f6;
}

.p-inputtext.p-invalid.p-component {
  border-color: #e24c4c;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-input-filled .p-inputtext {
  background-color: #e9ecef;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: #e9ecef;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: #ffffff;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-input-icon-left > i:first-of-type,
.p-input-icon-left > svg:first-of-type,
.p-input-icon-left > .p-input-prefix {
  left: 0.75rem;
  color: #6c757d;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.2s;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem !important;
  background-color: #ffffff;
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  align-self: center;
  text-align: left;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #16709e;
  color: white;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background: #16709e;
  color: white;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background: #16709e;
  color: white;
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #ffffff;
  color: #343a40;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: #ffffff;
  color: #495057;
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-confirm-dialog-reject {
  border: 1px solid #f4f7fb;
}

.p-confirm-dialog-reject:hover {
  background-color: #16709e;
  color: white;
  transition: background-color 0.2s, color 0.2s;
}

.p-confirm-dialog-accept {
  border: 1px solid #f4f7fb;
}

.p-confirm-dialog-accept:hover {
  background-color: #16709e;
  color: white;
  transition: background-color 0.2s, color 0.2s;
}

.p-menu-list {
  font-size: 12px;
  border: #b6b6b6;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  border-radius: 5px;
}

.p-menu {
  border: #16709e;
  border-style: solid;
  border-width: 1px;
  background-color: white;
  border-radius: 5px;
}

.p-menu-list > .p-menuitem {
  margin: 0px 8px 0px 0px;
  cursor: pointer;
  width: 100%;
}

.p-menu-list > .p-menuitem > a {
  color: #16709e;
}

.p-menu-list > .p-menuitem > a > .p-menuitem-icon {
  margin: 5px 8px;
}

.p-menu-list > div {
  display: inline-flex;
}

.p-menu-list > .p-menuitem > a:hover {
  color: white;
  background: #16709e;
  border-radius: 5px;
}

.p-tooltip .p-tooltip-text {
  background: #495057;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 6px;
  max-width: 200px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #495057;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #495057;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #495057;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #495057;
}

.p-button.p-button-link {
  color: #002060;
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
  background: transparent;
  color: #002060;
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
  border-color: transparent;
}

.p-button.p-button-link:enabled:active,
.p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
  background: transparent;
  color: #002060;
  border-color: transparent;
}

.nepc-pi-collapse-all {
  height: 1rem;
  width: 1rem;
  background-image: url('./icons/collapse-all.svg');
}

.nepc-pi-expand-all {
  height: 1rem;
  width: 1rem;
  background-image: url('./icons/expand-all.svg');
}

.p-tooltip.p-tooltip-top {
  position: fixed;
}

.p-radiobutton {
  width: 16px;
  height: 16px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #16709e;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
  border-color: #16709e;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: #16709e;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #16709e;
  background: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #16709e;
  background: #16709e;
  color: #ffffff;
}

.p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #e24c4c;
}

.p-radiobutton:focus {
  outline: 0 none;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #e9ecef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #e9ecef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #16709e;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #16709e;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1.25rem;
  background: #f8f9fa;
  color: #343a40;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-panel .p-panel-header .p-panel-title {
  color: #002161;
  font-weight: 700;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #343a40;
  border-color: transparent;
  background: #e9ecef;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.75rem 1.25rem;
}

.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #f3f9f5;
  color: #495057;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 0 none;
}

.p-panel .p-panel-footer {
  padding: 0.75rem 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #495057;
  border-top: 0 none;
}

.underMaintenance .loginArea {
  filter: blur(2px);
  pointer-events: none;
}

.underMaintenance .reasonArea {
  position: relative;
  z-index: 99999999;
}

.underMaintenance .reasonImage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
}

  .p-dropdown {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    user-select: none;
  }

  .p-dropdown-clear-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }

  .p-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .p-dropdown-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1 auto;
    width: 1%;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .p-dropdown-label-empty {
    overflow: hidden;
    opacity: 0;
  }

  input.p-dropdown-label {
    cursor: default;
  }

  .p-dropdown .p-dropdown-panel {
    min-width: 100%;
  }

  .p-dropdown-panel {
    position: absolute;
    top: 0;
    left: 0;
  }

  .p-dropdown-items-wrapper {
    overflow: auto;
  }

  .p-dropdown-item {
    cursor: pointer;
    font-weight: normal;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .p-dropdown-item-group {
    cursor: auto;
  }

  .p-dropdown-items {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .p-dropdown-filter {
    width: 100%;
  }

  .p-dropdown-filter-container {
    position: relative;
  }

  .p-dropdown-filter-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
  }

  .p-fluid .p-dropdown {
    display: flex;
  }

  .p-fluid .p-dropdown .p-dropdown-label {
    width: 1%;
  }

  .p-dropdown {
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    outline-color: transparent;
  }

  .p-dropdown:not(.p-disabled):hover {
    border-color: #2196f3;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    border-color: #2196f3;
  }

  .p-dropdown.p-variant-filled {
    background: #f8f9fa;
  }

  .p-dropdown.p-variant-filled:not(.p-disabled):hover {
    background-color: #f8f9fa;
  }

  .p-dropdown.p-variant-filled:not(.p-disabled).p-focus {
    background-color: #ffffff;
  }

  .p-dropdown.p-variant-filled:not(.p-disabled).p-focus .p-inputtext {
    background-color: transparent;
  }

  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.5rem;
  }

  .p-dropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
  }

  .p-dropdown .p-dropdown-label:focus,
  .p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none;
  }

  .p-dropdown .p-dropdown-trigger {
    background: transparent;
    color: #6c757d;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .p-dropdown .p-dropdown-clear-icon {
    color: #6c757d;
    right: 2.357rem;
  }

  .p-dropdown.p-invalid.p-component {
    border-color: #f44336;
  }

  .p-dropdown-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  .p-dropdown-panel .p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.5rem;
    margin-right: -1.5rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.5rem;
    color: #6c757d;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:first-child {
    margin-top: 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:last-child {
    margin-bottom: 0;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
    background: rgba(33, 150, 243, 0.24);
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #e9ecef;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item .p-dropdown-check-icon {
    position: relative;
    margin-left: -0.5rem;
    margin-right: 0.5rem;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }